import React, { useEffect, useState } from 'react';
import axios from 'axios';

const Matches: React.FC = () => {
  const [matches, setMatches] = useState([]);

  useEffect(() => {
    const fetchMatches = async () => {
      try {
        const response = await axios.get('http://localhost:8000/api/matches/');
        setMatches(response.data);
      } catch (error) {
        console.error('Error fetching matches', error);
      }
    };

    fetchMatches();
  }, []);

  return (
    <div>
      <h1>Matches</h1>
      <ul>
        {matches.map((match: any) => (
          <li key={match.id}>{match.home_team} vs {match.away_team}</li>
        ))}
      </ul>
    </div>
  );
};

export default Matches;
