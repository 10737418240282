import React, { useState } from "react";
import axios from "axios";

const PastPredictions: React.FC = () => {
  const [username, setUsername] = useState<string>("");
  const [predictions, setPredictions] = useState<any[]>([]);
  const [error, setError] = useState<string>("");

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError("");

    try {
      const response = await axios.post(
        "https://api.extraeuro2024.com/api/get-past-predictions/",
        {
          username,
        },
      );

      if (response.status === 200) {
        setPredictions(response.data);
      }
    } catch (error) {
      setError(
        "Tahminler alınırken bir hata oluştu. Lütfen kullanıcı adınızı kontrol edin.",
      );
    }
  };

  return (
    <div className="container">
      <header>
        <h1>Geçmiş Tahminler</h1>
      </header>
      <main>
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            placeholder="Kullanıcı Adı"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <button type="submit">Tahminleri Göster</button>
        </form>
        {error && <p>{error}</p>}
        {predictions.length > 0 && (
          <div>
            <h2>{username} kullanıcısının tahminleri:</h2>
            <ul>
              {predictions.map((prediction, index) => (
                <li key={index}>
                  <strong>{prediction.question}:</strong> {prediction.answer}
                </li>
              ))}
            </ul>
          </div>
        )}
      </main>
    </div>
  );
};

export default PastPredictions;
