import React, { useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

const SubmitPrediction: React.FC = () => {
  const { matchId } = useParams<{ matchId: string }>();
  const [choice, setChoice] = useState('');

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem('token');
      await axios.post(`http://localhost:8000/api/predictions/`, {
        match: matchId,
        choice,
      }, {
        headers: {
          Authorization: `Token ${token}`,
        },
      });
      toast.success('Prediction submitted');
    } catch (error) {
      console.error('Error submitting prediction', error);
      toast.error('Error submitting prediction');
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <label>Choice</label>
        <input type="text" value={choice} onChange={(e) => setChoice(e.target.value)} />
      </div>
      <button type="submit">Submit Prediction</button>
    </form>
  );
};

export default SubmitPrediction;
