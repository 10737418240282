import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../index.css"; // Ensure this file includes the required Tailwind CSS styles

const Home: React.FC = () => {
  const [showPopup, setShowPopup] = useState(false); // State for the popup
  const [username, setUsername] = useState("");
  const [predictions, setPredictions] = useState([]);
  const [showPredictions, setShowPredictions] = useState(false);

  const handleUsernameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value.replace(/\s/g, "");
    setUsername(newValue);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === " ") {
      e.preventDefault();
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        "https://api.extraeuro2024.com/api/check-or-create-username/",
        { username },
      );

      if (response.data.error) {
        toast.error(response.data.error);
      } else {
        const { access_token, has_submitted } = response.data;
        localStorage.setItem("access_token", access_token);
        if (has_submitted) {
          fetchPredictions(access_token);
        } else {
          toast.error(
            "Daha önce tahminde bulunmadığınız için sonuç görüntüleyemezsiniz. Tahmin gönderimi sona ermiştir.",
          );
        }
      }
    } catch (error) {
      console.error("Error checking or creating the username!", error);
      toast.error(
        "Kullanıcı adı kontrol edilirken veya oluşturulurken hata oluştu!",
      );
    }
  };

  const fetchPredictions = async (token: string) => {
    try {
      const response = await axios.post(
        "https://api.extraeuro2024.com/api/get-past-predictions/",
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );

      if (response.data.length === 0) {
        toast.error(
          "Daha önce tahminde bulunmadığınız için sonuç görüntüleyemezsiniz. Tahmin gönderimi sona ermiştir.",
        );
      } else {
        setPredictions(response.data);
        setShowPredictions(true);
      }
    } catch (error) {
      console.error("Error fetching past predictions!", error);
      toast.error("Tahminler getirilirken bir hata oluştu.");
    }
  };

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  const showRules = () => {
    togglePopup();
  };

  return (
    <div
      className="bg-overlay min-h-screen flex flex-col items-center justify-center"
      style={{ backgroundSize: "cover", backgroundPosition: "center" }}
    >
      <header className="text-center mb-8">
        <h1 className="text-4xl font-bold"></h1>
      </header>
      <main className="w-full max-w-5xl flex flex-col lg:flex-row justify-center lg:space-x-6 border rounded-md border-[#574223] shadow-md bg-stone-900 bg-opacity-75">
        <form
          onSubmit={handleSubmit}
          className="p-6 rounded-lg shadow-lg flex-1 flex flex-col mb-6 lg:mb-0 text-center"
        >
          <img
            src="https://i.ibb.co/vLLmfT8/77523f91-9f38-4890-81d9-3650bbc154a2.png"
            alt="Extra Toto"
            className="mx-auto mb-4 max-w-full"
          />
          <div>
            <label
              htmlFor="username"
              className="block text-lg font-medium text-gray-300 mb-2"
            >
              Kullanıcı Adınız
            </label>
            <input
              type="text"
              id="username"
              name="username"
              value={username}
              onChange={handleUsernameChange}
              onKeyDown={handleKeyDown}
              required
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-900 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>
          <div className="space-y-2 mt-4">
            <button
              type="submit"
              className="button-custom text-white font-bold py-2 px-4 rounded w-full transition duration-300"
            >
              Tahminini Gör
            </button>
            <button
              type="button"
              onClick={showRules}
              className="button-custom text-white font-bold py-2 px-4 rounded w-full transition duration-300"
            >
              Katılım Şartları
            </button>
          </div>
          <div className="mt-6 text-center bg-red-500 p-4 rounded-lg">
            <h2 className="text-xl font-bold text-white">
              EURO 2024 Avrupa Şampiyonası Tahmin Yarışması Tamamlandı!
            </h2>
          </div>
          <div className="mt-4 text-center bg-transparent p-4">
            <p className="mb-4">
              Değerli Üyelerimiz,
              <br />
              <br />
              EURO 2024 Avrupa Şampiyonası için düzenlemiş olduğumuz tahmin
              yarışmamız sona ermiştir. Belirlenen tarihe kadar tahminlerinizi
              iletmiş olduğunuz için teşekkür ederiz. Yarışmaya katılım süresi
              dolduğu için artık yeni tahminler kabul edilememektedir.
              <br />
              <br />
              Kullanıcı adınızı girerek "Tahminini gör" butonuna tıklamanız
              hâlinde, etkinlik içerisinde yapmış olduğunuz tahminleri
              görüntüleyebilirsiniz.
              <br />
              <br />
              Tüm katılımcılara teşekkür eder, bol şans dileriz.
            </p>
          </div>
        </form>
      </main>
      {showPredictions && (
        <section className="bg-stone-900 bg-opacity-75 p-6 flex-1 flex flex-col mt-4 w-full max-w-5xl border rounded-md border-[#574223] shadow-md">
          <h2 className="text-2xl font-semibold mb-4">Tahminleriniz</h2>
          <ul className="list-disc list-inside">
            {predictions.map((prediction: any, index) => (
              <li key={index}>
                {prediction.question}: {prediction.answer}
              </li>
            ))}
          </ul>
        </section>
      )}
      <section className="bg-stone-900 bg-opacity-75 p-6 flex-1 flex flex-col mt-4 w-full max-w-5xl border rounded-md border-[#574223] shadow-md">
        <img
          src="/prizes.png"
          alt="Extra Toto"
          className="mx-auto mb-4 max-w-full"
        />
      </section>
      <ToastContainer />
      {showPopup && (
        <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50 p-4">
          <div className="bg-stone-900 text-white p-4 rounded-lg max-w-full w-full md:max-w-2xl mx-auto overflow-y-auto max-h-full">
            <h2 className="text-2xl font-bold mb-4">
              Ödül Havuzu ve Katılım Şartları
            </h2>
            <div className="overflow-y-auto max-h-screen p-4">
              <p className="font-bold">ÖDÜL HAVUZU</p>
              <p>
                • ExtraEuro2024, EURO 2024 için hazırladığımız 500.000 TL ödül
                havuzlu spor tahmin yarışmasıdır. Doğru tahmin sayısına göre
                toplam ödül aşağıdaki şekilde bölüştürülecektir:
              </p>
              <ul className="list-disc list-inside mb-4">
                <li>10 Doğru Tahmin 200.000 TL</li>
                <li>9 Doğru Tahmin 100.000 TL</li>
                <li>8 Doğru Tahmin 80.000 TL</li>
                <li>7 Doğru Tahmin 50.000 TL</li>
                <li>6 Doğru Tahmin 40.000 TL</li>
                <li>5 Doğru Tahmin 30.000 TL</li>
              </ul>
              <p>
                • 10, 9, 8, 7, 6 ve 5 doğru tahminde bulunan üyelerimiz arasında
                belirtilen ödüller eşit şekilde paylaştırılacaktır.
              </p>
              <p>
                • Ödüller çevrim şartsız bakiye olarak eklenmekte olup, ana para
                çevrim şartına tabidir. İlgili ödüller ile çekim yapabileceğiniz
                minimum tutar 200 TL, maksimum tutar ise almış olduğunuz ödülün
                5 katıdır.
              </p>
              <p>
                • Turnuvanın final karşılaşması tamamlandıktan sonraki 72 saat
                içinde ödüller kazananların hesabına otomatik aktarılacaktır.
              </p>
              <p>
                • Hesabınızda bakiye veya açık kupon olması durumunda sistem
                tarafından ekleme yapılamamaktadır. Bu kural nedeniyle hesabına
                ödülü eklenmeyen üyeler, final maçı bittikten sonraki 72 saat
                içerisinde, bakiyeleri ve açık bahisleri bulunmadığı takdirde,
                Canlı Destek hattımız üzerinden taleplerini iletebilirler.
              </p>
              <p className="font-bold mt-4">KURALLAR</p>
              <p>
                • ExtraEuro2024'e katılabilmeniz için 10.06.2024 saat 00:00 -
                14.06.2024 saat 21:30 arasında tek seferde minimum 1.000 TL ve
                üzeri para yatırma işlemi gerçekleştirmiş olmanız gerekmektedir.
              </p>
              <p>
                • Tahminlerinizi ilk karşılaşmanın başlama saati olan 14.06.2024
                saat 22:00'a kadar iletmeniz gerekmektedir. Tahmin ekranı
                belirtilen saatten sonra kapatılacaktır.
              </p>
              <p>
                • Etkinlik için yatırım şartını gerçekleştirmiş üyelerimizin,
                etkinliğe yalnızca bir adet katılım hakkı bulunmaktadır.
              </p>
              <p>
                • Tahminde bulunurken kullanıcı adınızı hatalı girmeniz
                durumunda tahminleriniz geçersiz sayılmaktadır.
              </p>
              <p>
                • Bir üyenin birden fazla tahminde bulunduğu veya farklı
                hesaplarla etkinliğe katıldığı tespit edilirse tüm kazançları
                geçersiz sayılacaktır.
              </p>
              <p>
                • Etkinlik sonuçlandırması UEFA'nın EURO 2024 için hazırlamış
                olduğu www.uefa.com/euro2024/ resmi sayfaya göre sağlanacaktır.
              </p>
              <p>
                • Birden fazla doğru cevabı bulanan sorular için doğru
                cevapların hepsi kabul edilecektir. (Örn: Turnuva boyunca
                kalesinde en az gol gören ülkeler birden fazla olabilir bu
                durumda en az gol yiyen tüm takımlar doğru cevap kabul
                edilecektir.)
              </p>
              <p>
                • Turnuvanın final karşılaşması tamamlandıktan sonraki 72 saat
                içinde ödüller kazananların hesabına otomatik aktarılacaktır.
              </p>
              <p>
                • Hesabınızda bakiye veya açık kupon olması durumunda sistem
                tarafından ekleme yapılamamaktadır. Bu kural nedeniyle hesabına
                ödülü eklenmeyen üyeler, final maçı bittikten sonraki 72 saat
                içerisinde, bakiyeleri ve açık bahisleri bulunmadığı takdirde,
                Canlı Destek hattımız üzerinden taleplerini iletebilirler.
              </p>
            </div>
            <button
              onClick={togglePopup}
              className="mt-4 button-custom text-white font-bold py-2 px-4 rounded w-full transition duration-300"
            >
              Kapat
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Home;
