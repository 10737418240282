import React from 'react';

const Rules: React.FC = () => (
  <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 p-4">
    <div className="bg-white shadow-md rounded-lg p-6 max-w-3xl w-full">
      <h1 className="text-2xl font-bold mb-4 text-black">KURALLAR</h1>
      <ul className="list-disc pl-6 space-y-2 text-black">
        <li>
          ExtraEuro2024'e katılabilmeniz için XX.06.2024 saat 00:00 - 14.06.2024 saat 22:00 arasında tek seferde minimum 1.000 TL ve üzeri yatırımınız olması gerekmektedir.
        </li>
        <li>
          Tahminlerinizi ilk karşılaşmanın başlama saatine kadar iletmeniz gerekmektedir.
        </li>
        <li>
          Her üyemizin bir adet tahmin hakkı bulunmaktadır.
        </li>
        <li>
          Tahminde bulunurken kullanıcı adınızı hatalı girmeniz durumunda tahminleriniz geçersiz sayılmaktadır.
        </li>
        <li>
          Bir üyenin birden fazla tahminde bulunduğu veya farklı hesaplarla etkinliğe katıldığı tespit edilirse tüm kazançları geçersiz sayılacaktır.
        </li>
        <li>
          Etkinlik sonuçlandırması 90 dakikalık normal süre ve hakemin ek olarak vereceği 90+ uzatma süreleri sonundaki skora göre yapılmaktadır.
        </li>
      </ul>
    </div>
  </div>
);

export default Rules;
